import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setMenuY, setMenuZIndex } from '../app/features/menuSlice'
import { setCurrentPage } from '../app/features/currentPageSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faMagnifyingGlass, faSection, faHeart, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'


function Menu() {

    const menu = useSelector((state) => state.menu)
    const currentPage = useSelector((state) => state.currentPage)
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [menuHeight, setMenuHeight] = useState(100);
    const [transitionTop, setTransitionTop] = useState('top 0.4s ease');

    const dispatch = useDispatch();

    const handleTouchStart = (e) => {
        setMenuHeight(document.getElementsByClassName("menu")[0].offsetHeight)

        setTransitionTop('0s')
        setTouchStart(e.targetTouches[0].clientY);
    }

    const handleTouchMove = (e) => {
        let currentPosY = e.targetTouches[0].clientY;
        let difference = currentPosY - touchStart

        if (difference > 0) {
            dispatch(setMenuY( e.targetTouches[0].clientY - touchStart + 100))
            setTouchEnd(e.targetTouches[0].clientY);
        }
    }

    const handleTouchEnd = () => {
        setTransitionTop('top 0.4s ease')
        if (touchEnd - touchStart > menuHeight/3) {
            dispatch(setMenuY('100%'))
            setTimeout(() => {
                dispatch(setMenuZIndex(-1))
            }, 400);
            
        } else {
            dispatch(setMenuY(100))
        }
    }

    const handleClick  = (e) => {
        if(menu.menuY === 100) {
            dispatch(setMenuY('100%'))
            setTimeout(() => {
                dispatch(setMenuZIndex(-1))
            }, 400);
        }
    }

    const handleLinkClick = (pagePath) => {
        dispatch(setCurrentPage(pagePath))
        dispatch(setMenuY('100%'))
        setTimeout(() => {
            dispatch(setMenuZIndex(-1))
        }, 400);
    }


    return (
        <div className={"menu " + (menu.menuY === '100%'? "menu-bg-light" : "menu-bg-dark")} style={{zIndex: (menu.category==="menu"? menu.zIndex : -1)}} >
            <div style={{top: menu.menuY, transition: transitionTop}} className="menu-list">
                <div className="menu-handle" 
                     onTouchStart={(e)=>handleTouchStart(e)}
                     onTouchMove={(e)=>handleTouchMove(e)}
                     onTouchEnd={(e)=>handleTouchEnd(e)}
                     onClick={(e)=>handleClick(e)}                          
                >
                    <hr></hr>
                    <hr></hr>
                </div>
                <Link className={"text-neutralDark no-underline flex border-solid border-b border-neutralLight hover:text-primary hover:font-bold focus:text-primary focus:font-bold" + (currentPage.currentPage==="/" ? " font-bold text-primary" : "")} 
                      to='/' onClick={()=>handleLinkClick('/')}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="text-[2rem] w-[3rem] py-[1.5em]"/>
                    <p className="flex w-full pl-[2em] items-center">Search</p>
                    <FontAwesomeIcon icon={faChevronRight} className="text-[2rem] w-[3rem] py-[1.5em]"/>
                </Link>
                   
                <Link className={"text-neutralDark no-underline flex border-solid border-b border-neutralLight hover:text-primary hover:font-bold focus:text-primary focus:font-bold" + (currentPage.currentPage==="/myfavourites" ? " font-bold text-primary" : "")} 
                      to='/myfavourites' onClick={()=>handleLinkClick('/myfavourites')}>
                    <FontAwesomeIcon icon={faHeart} className="text-[2rem] w-[3rem] py-[1.5em]"/> 
                    <span className="flex w-full pl-[2em] items-center">My Favourites</span>
                    <FontAwesomeIcon icon={faChevronRight} className="text-[2rem] w-[3rem] py-[1.5em]"/>
                </Link>

                <Link className={"text-neutralDark no-underline flex border-solid border-b border-neutralLight hover:text-primary hover:font-bold focus:text-primary focus:font-bold" + (currentPage.currentPage==="/info" ? " font-bold text-primary" : "")} 
                      to='/info' onClick={()=>handleLinkClick('/info')}>
                    <FontAwesomeIcon icon={faInfoCircle} className="text-[2rem] w-[3rem] py-[1.5em]"/> 
                    <span className="flex w-full pl-[2em] items-center">Info</span>
                    <FontAwesomeIcon icon={faChevronRight} className="text-[2rem] w-[3rem] py-[1.5em]"/>
                </Link>
                    
                <Link className={"text-neutralDark no-underline flex border-solid border-b border-neutralLight hover:text-primary hover:font-bold focus:text-primary focus:font-bold" + (currentPage.currentPage==="/imprint" ? " font-bold text-primary" : "")} 
                      to='/imprint' onClick={()=>handleLinkClick('/imprint')}>
                    <FontAwesomeIcon icon={faSection} className="text-[2rem] w-[3rem] py-[1.5em]"/>
                    <span className="flex w-full pl-[2em] items-center">Impressum</span>
                    <FontAwesomeIcon icon={faChevronRight} className="text-[2rem] w-[3rem] py-[1.5em]"/>
                </Link>
            </div>
        </div>
    )
}

export default Menu