// /bbname/Scripts/activate.bat
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
// import { useLazyFetchNamesQuery } from '../app/api/nameSlice'
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from '../app/features/filterSlice'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



function Info() {

const dispatch = useDispatch();
const filter = useSelector((state) => state.filter);
const navigate = useNavigate();
const [redirect, setRedirect] = useState(false);
const [selectedGender, setSelectedGender] = useState(null);


const handleClick = (gender) => {
  dispatch(setFilter({ ...filter, gender }));
  setSelectedGender(gender); // Store selected gender
  setRedirect(true); // Set flag to redirect after state update
  navigate("/");
};

useEffect(() => {
  if (redirect && selectedGender) {
    navigate("/"); // Navigate after filter is updated
  }
}, [filter, redirect, navigate, selectedGender]);

    return (
      <div className='row-start-2 col-start-1 row-end-2 col-end-1 pb-[4em] overflow-y-auto min-[650px]:row-start-1 min-[650px]:col-start-2 min-[650px]:row-end-3 min-[650px]:col-end-2 bg-neutral'>
        <header>
          {/* <div className='flex flex-col min-[650px]:grid min-[650px]:place-items-center'> */}
          <div className="flex flex-col min-[650px]:grid min-[650px]:grid-cols-2 min-[650px]:items-center">

            {/* <div className="flex items-center justify-center min-[650px]:row-start-1  min-[650px]:col-start-1"> */}
            <div className="flex items-center justify-center">
            <img
                alt="Jetzt Namen finden"
                src="/images/storch2.png"
                className="block mt-2  ml-auto" //max-w-[75%]
                />
            </div>

            <div className="flex flex-col justify-center self-end min-[650px]:justify-self-start min-[650px]:row-start-1  min-[650px]:col-start-1 min-[650px]:pt-[10%]">
              <h1 className='className="text-3xl font-bold mb-4 text-center min-[650px]:text-left px-[5%] min-[650px]:pb-[5%]' style={{ fontSize: '20px', color: '#0069af' }}>Willkommen bei <a href="/"className={
          "text-primary hover:text-secondary focus:text-secondary"}>myBabyNameFinder</a>!</h1>
              <h2 className="text-center min-[650px]:text-left px-[5%] text font-semibold block mt-2 text-gray-600 min-[650px]:pb-[5%]" 
              style={{ fontSize: '16px', color: '#666666' }}>
                myBabyNameFinder ist deine kostenlose Plattform, 
                um den perfekten Namen für dein Baby zu finden. 
                Egal ob du nach <strong>klassischen</strong>, <strong>modernen</strong>,
                 <strong>seltenen</strong> oder <strong>ausgefallenen Babynamen</strong> 
                  suchst – hier wirst du fündig!</h2>
              <div className='flex justify-center space-x-4 min-[650px]:justify-start min-[650px]:px-[5%] pt-[5%]'>
              <button
                  onClick={() => handleClick("f")}
                  className="no-deco bg-secondary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-secondaryDark text-center">
                  Mädchennamen <FontAwesomeIcon icon={faVenus} className="text-[20px] pl-3" />
              </button>
              <button
                  onClick={() => handleClick("m")}
                  className="no-deco bg-primary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark text-center">
                  Jungennamen <FontAwesomeIcon icon={faMars} className="text-[20px] pl-3"/>
                </button>
              </div>
            </div>
          </div>
        </header>

      <section className="mt-8">
        <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
          <img src="/images/teddy.jpg" alt="Baby Namen einfach filtern" className="my-4 min-[770px]:w-1/2" />
          
          <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
          <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
          Finde den perfekten Babynamen,
            <small className="text font-semibold block mt-2 text-gray-600" style={{ fontSize: '16px', color: '#666666' }}>
              es war noch nie so einfach!
            </small>
          </h2>
          <p className="px-[5%]">
          Die Suche nach dem idealen Namen war noch nie so einfach! 
          Stöbere in unserer umfangreichen Sammlung an <strong>schönen Jungennamen und Mädchennamen</strong>, 
          entdecke <strong>moderne Namen, nordische Babynamen, skandinavische Babynamen, außergewöhnliche Mädchennamen </strong>und<strong> seltene Babynamen</strong>. 
          Ob <strong>deutsche Jungennamen, amerikanische Namen, kurze moderne Jungennamen, beliebte Jungennamen 2023, 2024 und 2025 </strong> 
           oder <strong>coole Jungennamen</strong> 
          – unser Namensfinder hilft dir, den perfekten Namen zu entdecken.
          <br></br>
          <br></br>
          </p>
          <div className='font-bold px-[5%]'>
            <span 
              className='badge' 
              style={{
                backgroundColor: 'rgba(76, 175, 80, 1)', 
                color: 'white', 
                padding: '0.25em 0.4em',
                marginRight: '0.2em',
                borderRadius: '0.8rem'}}
            >
            NEU!
            </span>
              Benutze einen unserer vielen verfügbaren Filter, um noch einfacher den perfekten Namen für dein Baby zu finden.
          </div>
          </div>
        </div>

      </section>

      <section className="mt-8">
      <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
        <img src="/images/schnuller1.png" alt="Nutze einen unser vielen Features" className="my-4 min-[770px]:w-1/2 min-[650px]:order-2 " />
        
        <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Unsere Features
          <small className="text font-semibold block mt-2 text-gray-600" style={{ color: '#666666', fontSize: '16px' }}>
          
          </small>
        </h2>
        <p className='px-[5%]'>
        <ul className="list-disc list-inside pb-4">
            <li><strong>Umfangreiche <a href="/"className={
          "text-primary hover:text-secondary focus:text-secondary"}>Namenssammlung</a>:</strong> Durchsuche unser Sammlung von Babynamen und entdecke eine Vielzahl an beliebten Jungennamen und Mädchennamen.</li>
            <li><strong>Filterfunktion:</strong>  Finde deine Top 10 Jungennamen, Top 10 Mädchennamen oder moderne Mädchennamen und Jungennamen mit nur wenigen Klicks.e zwischen Geschlecht, um personalisierte Vorschläge für Jungen- oder Mädchennamen zu erhalten.</li>
            <li><strong><a href="/myfavourites"className={
          "text-primary hover:text-secondary focus:text-secondary"}>Favoritenliste</a>:</strong> Speichere besondere Jungennamen, ausgefallene Mädchennamen, klangvolle männliche und weibliche Vornamen und vergleiche sie, um den perfekten Namen zu finden.</li>
            <li><strong>Bewertungssystem:</strong> Bewerte Namen, damit sie nach deiner Vorliebe auf der Favoritenliste angezeigt werden.</li>
            <li><strong>Eigenen Namen hinzufügen:</strong> Dein Lieblingsname fehlt? Füge einfach deinen eigenen Namen zur Favoritenliste hinzu.</li>
            <li><strong>PDF-Druck oder Speichern:</strong> Drucke oder speichere deine <a href="/myfavourites"className={
          "text-primary hover:text-secondary focus:text-secondary"}>Favoritenliste</a> als PDF und teile sie mit deinen Liebsten.</li>
          </ul>
        </p>
        </div>
        </div>
      </section>

      <section className="mt-8">
      <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
        <img src="/images/flasche2.png" alt="Tipps zur Namenswahl" className="my-4 min-[770px]:w-1/2" />
  
        <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Tipps
          <small className="text font-semibold block mt-2 text-gray-600" style={{ color: '#666666', fontSize: '16px' }}>
        zur Namenswahl:
          </small>
        </h2>
        <p className='px-[5%]'>
        <ul className="list-disc list-inside pb-4">
            <li><strong>Klang und Harmonie:</strong> Achte darauf, dass der Vorname für dein Baby gut mit dem Nachnamen harmoniert.</li>
            <li><strong>Bedeutung:</strong> Wähle einen Namen mit einer positiven, tiefgründige und schönen Bedeutung.</li>
            <li><strong>Aussprache und Schreibweise:</strong> Vermeide komplizierte Schreibweisen.</li>
            <li><strong>Zeitlosigkeit:</strong> Überlege, ob der Name auch in Zukunft passt.</li>
            <li><strong>Geschwisterkompatibilität:</strong> Falls du schon Kinder hast, können die Namen zusammenpassen.</li>
          </ul>
        </p>
      </div>
      </div>
      </section>

      <section className="mt-8">
        <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
          <img src="/images/teddy.jpg" alt="Baby Namen einfach filtern" className="my-4 min-[770px]:w-1/2  min-[650px]:order-2" />
          
          <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
          <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
          Beliebteste Babynamen
            <small className="text font-semibold block mt-2 text-gray-600" style={{ fontSize: '16px', color: '#666666' }}>
              2023, 2024 und 2025!
            </small>
          </h2>
          <p className="px-[5%]">
          Du fragst dich: <i>Was sind die beliebtesten Namen? </i> 
          Wir haben die <strong>beliebtesten Vornamen 2023, 2024</strong> und <strong>2025 </strong>
          für dich gesammelt. 
          Lass dich inspirieren und wähle <strong>coole Jungennamen</strong>, 
          <strong> süße Jungennamen</strong>, <strong>klangvolle Jungennamen</strong>, 
          <strong> männliche Vornamen</strong>, <strong>seltene Babynamen</strong>, 
          <strong> moderne Babynamen</strong> und mehr!
          <br></br>
          <br></br>
          Auch in den vergangenen Jahren waren <strong>Babynamen 2022</strong> und 
          <strong> Babynamen 2023</strong> voller Trends, die du in unsere Sammlung entdecken kannst. 
          Egal ob <strong>schöne Namen</strong>, <strong>beliebteste Vornamen</strong> oder <strong>seltene Jungennamen und Mädchennamen</strong> – hier findest du alles!
          <br></br>
          <br></br>
          </p>
          </div>
        </div>

      </section>

      <section className="mt-8">
      <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
        <img src="/images/krippe2.png" alt="Storch bringt Baby" className="my-4 min-[650px]:w-1/2" />
        
        <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Schritt-für-Schritt-Anleitung:
          <small className="text font-semibold block mt-2 text-gray-600" style={{ color: '#666666', fontSize: '16px' }}>
          </small>
        </h2>
        <p className='px-[5%]'>
        <ul className="list-disc list-inside pb-4">
            <li><strong>Namenssuche:</strong> Durchsuche Internet, Bücher und Zeitschriften nach interessanten Namen.</li>
            <li><strong>Liste erstellen:</strong> Notiere alle Namen, die dir gefallen, und bespreche sie mit deinem Partner.</li>
            <li><strong>Auswahl eingrenzen:</strong> Streiche Namen, die zu häufig vorkommen oder schwer auszusprechen sind.</li>
            <li><strong>Vorlesetest:</strong> Lass die Namen von anderen laut vorlesen, um die Aussprache zu prüfen.</li>
            <li><strong>Partnerfeedback:</strong> Zeige deinem Partner die Liste und streiche Namen, die ihm/ihr nicht gefallen.</li>
            <li><strong>Kombinationstest:</strong> Teste die verbleibenden Namen in Kombination mit eurem Nachnamen.</li>
          </ul>
        </p>
        </div>
        </div>
      </section>


      <section className="mt-8">
      <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
        <img src="/images/mobile.png" alt="Baby mobile" className="my-4 min-[770px]:w-1/2  min-[650px]:order-2" />
        
        <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Rechtliche Aspekte:
        </h2>
        <p className='px-[5%]'>
        In Deutschland muss der Vorname das Geschlecht des Kindes erkennen lassen und darf nicht beleidigend 
            oder lächerlich sein. <strong>Kindernamen für Jungs</strong> und <strong>Mädchennamen</strong> sollten daher den offiziellen 
            Namensrichtlinien entsprechen. Ungewöhnliche Namen sind erlaubt, solange sie diesen Regeln entsprechen. 
            Außerdem sollten die Namen nicht zu viele sein – maximal fünf Vornamen sind erlaubt.
        <br></br>
        <br></br>
        </p>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Lass dich von unseren Vornamen inspirieren!
        </h2>
        <p className='px-[5%]'>
        Nutze unseren Babynamenfinder, um <strong>beliebte Jungennamen</strong>, 
        <strong> moderne Mädchennamen</strong>, <strong>süße Jungennamen</strong>, 
        <strong> schöne Jungennamen</strong> und <strong>Mädchennamen</strong> oder 
        <strong> besondere Jungennamen</strong> zu entdecken. 
        Wähle das Geschlecht aus und filtere nach deinen Vorlieben. 
        So findest du schnell und einfach den perfekten Namen für dein Baby!
        <br></br>
        <br></br>
        Lass dich von BabyNameFinder inspirieren und finde den Namen, der dein Herz höher schlagen lässt - für einen perfekten Start ins Leben!
        <br></br>
        <br></br>
        </p>
        </div>
        </div>
      </section>

      <section class="w-full w-md:w-1/2 h-100 flex flex-col justify-center ms-md-5 align-items-start">
        <h2 className=" text-3xl font-bolder mb-4 text-center" 
        style={{ fontSize: '20px', color: '#666666' }}>
          WORAUF WARTEST DU NOCH?
        </h2>

        <div className="flex w-75 mx-auto mx-md-0 flex-row gap-md-5 gap-2 mt-5 justify-content-center justify-content-md-start align-item-center align-items-md-start">
                <button
                  onClick={() => handleClick("f")}
                  className="no-deco bg-secondary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-secondaryDark text-center"
                >
                  Mädchennamen <FontAwesomeIcon icon={faVenus} className="text-[20px] pl-3" />
                </button>
                <button
                  onClick={() => handleClick("m")}
                  className="no-deco bg-primary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark text-center"
                >
                  Jungennamen <FontAwesomeIcon icon={faMars} className="text-[20px] pl-3"/>
                </button>

        </div>
      </section>
      </div>

      );      
}

export default Info